
import ValidationMixin from "@/mixins/validation";
import {
  PriorityTypes,
  IssuePriority,
} from "@/store/modules/priority/priority.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const priorityX = namespace("Priority");

@Component({})
export default class EditPriority extends Mixins(ValidationMixin) {
  @priorityX.State(PriorityTypes.DELETE_PRIORITY_STATE)
  public deletePriority!: DefaultState;

  @priorityX.State(PriorityTypes.PRIORITY_DATA)
  public issuePriority!: IssuePriority;

  @priorityX.Mutation(PriorityTypes.SET_DELETE_PRIORITY_DIALOG)
  public setDeletePriority!: (deletePriority: boolean) => void;

  @priorityX.Action(PriorityTypes.DELETE_PRIORITY)
  public deleteIssuePriority!: () => Promise<void>;

  public updatePriorityStep = 1;

  public isValid = false;

  @Ref("form") public formRef!: Validator;

  closeDeletePriorityDialog(): void {
    this.setDeletePriority(false);
  }
}
